<script>
export default {
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
    projectFullPath: {
      type: String,
      required: true,
    },
    path: {
      type: String,
      required: true,
    },
  },
};
</script>

<template>
  <div
    class="gl-font-base gl-font-weight-bold gl-py-7 gl-display-flex gl-align-items-center gl-justify-content-center"
  >
    {{
      s__(
        'JH|ContentValidation|According to the relevant laws and regulations, this content is not displayed. To appeal, please click',
      )
    }}<span
      class="gl-text-blue-500 gl-cursor-pointer js-appeal-modal gl-ml-2"
      :data-id="id"
      :data-project-full-path="projectFullPath"
      :data-path="path"
      >{{ s__('JH|ContentValidation|here') }}</span
    >
  </div>
</template>
